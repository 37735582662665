import { Link as GatsbyLink } from "gatsby"
import React from "react"

export default function Link({ to, as: asTag, children, ...rest }) {
  let Tag = asTag

  if (!asTag) {
    Tag = to ? (to.toString().startsWith("http") ? "a" : GatsbyLink) : "button"
  }

  const props = {}
  to && to.startsWith("http") ? (props.href = to) : (props.to = to)
  return (
    <Tag {...props} {...rest}>
      {children}
    </Tag>
  )
}
