import React, { useMemo, useState } from "react";
import Link from "./Link";
import { BsCaretDownFill } from "react-icons/bs";
import { FaGlasses, FaHeart, FaGem, FaUserAlt } from "react-icons/fa";
import { IoIosLogIn } from "react-icons/io";
import { HiOutlineMenuAlt3, HiX } from "react-icons/hi";
import { AnimatePresence, motion } from "framer-motion";
import cn from "classnames";
import { useEffect } from "react";
import LanguagePicker from "./LanguagePicker";
import Collapse from "./Collapse";
import { graphql, StaticQuery } from "gatsby";
import get from "lodash.get";
import { css } from "@emotion/core";

import GatsbyImage from "gatsby-image";

function Header({ data }) {
  const [mobileIsOpen, setMobileIsOpen] = useState(false);
  const menu = useMemo(() => {
    function recursive(items) {
      return items.map(item => {
        let { link, label, subnav } = get(item, "link.document.data", {});
        if (!subnav || (subnav && subnav.length === 0)) {
          subnav = undefined;
        } else {
          subnav = recursive(subnav);
        }
        return { link, label, subnav: subnav };
      });
    }

    let items = get(data, "prismicHeader.data.navigation");
    items = recursive(items);
    return { items };
  });
  const Logo = get(data, "prismicHeader.data.logo");

  return (
    <>
      <div className="absolute top-0 z-50 w-full py-6 md:pt-16 md:pb-8">
        <div className="container">
          <div className="flex lg:flex-wrap  items-center justify-between">
            <Link
              to="/"
              className="block w-full tablet:w-24"
              style={{
                maxWidth: Logo.dimensions.width,
              }}
            >
              {Logo.fluid && <GatsbyImage fluid={Logo.fluid} />}
            </Link>

            <DesktopMenu menu={menu} />
            <div className="space-x-4 ml-auto lg:flex items-center hidden">
              {/*<LanguagePicker />
              <span>|</span>
              */}
              <UserAuthItems />
            </div>
            <div
              className="lg:hidden p-4 -mr-4 relative z-50"
              onClick={() => {
                setMobileIsOpen(!mobileIsOpen);
              }}
            >
              {!mobileIsOpen && <HiOutlineMenuAlt3 className="w-5 h-5" />}
              {mobileIsOpen && <HiX className="w-5 h-5" />}
            </div>
          </div>
        </div>
        <div className="w-full tablet:hidden p absolute bottom-0 left-0">
          <div className="container">
            <div
              css={css`
                opacity: 0.1;
                border-bottom: 1px solid #ffffff;
              `}
            ></div>
          </div>
        </div>
      </div>

      <MobileMenu
        isOpen={mobileIsOpen}
        menu={menu}
        close={() => {
          setMobileIsOpen(false);
        }}
      />
    </>
  );
}
export default () => (
  <StaticQuery
    query={graphql`
      {
        prismicHeader {
          data {
            navigation {
              link {
                document {
                  ... on PrismicLink {
                    data {
                      link
                      label
                      target
                      subnav {
                        link: link1 {
                          document {
                            ... on PrismicLink {
                              data {
                                label
                                target
                                link
                                subnav {
                                  link: link1 {
                                    document {
                                      ... on PrismicLink {
                                        data {
                                          label
                                          target
                                          link
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
            logo {
              dimensions {
                width
              }
              fluid {
                ...GatsbyPrismicImageFluid
              }
            }
          }
        }
      }
    `}
    render={data => <Header data={data} />}
  />
);
const variants = {
  open: {
    opacity: 1,
    y: 0,
    pointerEvents: "auto",
    transition: {
      duration: 0.2,
    },
  },
  closed: {
    opacity: 0,
    y: 10,
    pointerEvents: "none",
    transition: {
      duration: 0.2,
    },
  },
};
const variantsSecond = {
  open: {
    opacity: 1,

    x: "100%",
    pointerEvents: "auto",
    transition: {
      duration: 0.2,
    },
  },
  closed: {
    opacity: 0,

    x: "calc(100% + 20px)",
    pointerEvents: "none",
    transition: {
      duration: 0.2,
    },
  },
};

function DesktopMenu({ menu }) {
  const [isOpen, setIsOpen] = useState(null);

  function isFirst(i) {
    return isOpen && isOpen.split("-")[0] == i.toString();
  }
  function isSecond(i) {
    return isOpen && isOpen.split("-")[1] == i.toString();
  }

  useEffect(() => {
    if (typeof window !== "undefined") {
      function handler(e) {
        if (
          e.target.closest(".dropdown") ||
          e.target.closest(".dropdown-item")
        ) {
        } else {
          setIsOpen(null);
        }
      }
      document.addEventListener("click", handler);
      return () => {
        document.removeEventListener("click", handler);
      };
    }
  }, []);

  return (
    <ul className="space-x-6 tablet:hidden lg:ml-auto">
      {menu.items.map(({ link, label, subnav, target }, i) => (
        <li className="inline-block relative " key={i}>
          <Link
            to={link}
            target={target}
            activeClassName="font-semibold"
            onClick={e => {
              if (subnav) {
                e.preventDefault();
                isFirst(i) ? setIsOpen(null) : setIsOpen(`${i}`);
              } else {
                setIsOpen(null);
              }
            }}
            className={cn(
              " flex items-center px-4 pb-2 pt-4 text-sm hover:font-semibold",
              {
                "bg-dark bg-opacity-75 font-semibold dropdown ": isFirst(i),
              }
            )}
          >
            {label}{" "}
            {subnav && (
              <BsCaretDownFill
                className={cn("ml-4 w-3 h-3", {
                  "transform rotate-180": isFirst(i),
                })}
              />
            )}{" "}
          </Link>
          <AnimatePresence>
            {subnav && isFirst(i) && (
              <motion.div
                variants={variants}
                initial={"closed"}
                exit={"closed"}
                animate={"open"}
                className="dropdown-item absolute bg-dark bg-opacity-75  "
              >
                {subnav.map(({ link, label, subnav, target }, ci, ca) => {
                  return (
                    <div
                      key={ci}
                      className={cn("relative", {
                        open: isSecond(ci),
                      })}
                    >
                      {" "}
                      <Link
                        to={link}
                        target={target}
                        onClick={e => {
                          if (subnav) {
                            e.preventDefault();
                            if (isSecond(ci)) {
                              setIsOpen(isOpen.split("-")[0]);
                            } else {
                              setIsOpen(`${isOpen.split("-")[0]}-${ci}`);
                            }
                          } else {
                            setIsOpen(null);
                          }
                        }}
                        className={cn(
                          "    px-4 pb-2 pt-4  block w-56 leading-normal text-sm hover:font-semibold border-b-4  border-primary  border-opacity-0 hover:border-opacity-100 "
                        )}
                      >
                        <span className="inline-flex items-center w-full">
                          {label}
                          {subnav && (
                            <BsCaretDownFill className="ml-auto w-3 h-3 transform -rotate-90" />
                          )}
                        </span>
                      </Link>
                      <AnimatePresence>
                        {subnav && isSecond(ci) && (
                          <motion.div
                            variants={variantsSecond}
                            initial={"closed"}
                            animate={"open"}
                            exit={"closed"}
                            className="dropdown-item absolute   bg-dark bg-opacity-75  divide-y divide-white divide-opacity-25 top-0 right-0   "
                          >
                            {subnav.map(({ label, link, target }, cci) => {
                              return (
                                <Link
                                  key={cci}
                                  target={target}
                                  to={link}
                                  onClick={e => {
                                    setIsOpen(null);
                                  }}
                                  className={cn(
                                    " text-sm   px-4 pb-2 pt-4  block w-56 leading-normal hover:font-semibold border-b-4  border-primary  border-opacity-0 hover:border-opacity-100 "
                                  )}
                                >
                                  <span className="inline-flex items-center w-full">
                                    {label}
                                  </span>
                                </Link>
                              );
                            })}
                          </motion.div>
                        )}
                      </AnimatePresence>
                    </div>
                  );
                })}
              </motion.div>
            )}
          </AnimatePresence>
        </li>
      ))}
    </ul>
  );
}

const menuVariant = {
  visible: {
    x: 0,
    transition: {
      ease: "easeOut",
      delayChildren: 0.2,
      staggerChildren: 0.05,
    },
  },
  hidden: {
    x: "100%",
    transition: {
      ease: "easeIn",
      staggerChildren: 0.1,
      delayChildren: -0.3,
    },
  },
};
const itemsVariants = {
  visible: {
    opacity: 1,
    x: 0,
    transition: {
      ease: "easeOut",
    },
  },
  hidden: {
    opacity: 0,
    x: 30,
    transition: {
      ease: "easeIn",
    },
  },
};
function MobileMenu({ menu, isOpen, close }) {
  useEffect(() => {
    if (typeof window !== "undefined") {
      if (isOpen) {
        document.body.classList.add("overflow-hidden");
      } else {
        document.body.classList.remove("overflow-hidden");
      }
      return () => {
        document.body.classList.remove("overflow-hidden");
      };
    }
  }, [isOpen]);
  return (
    <motion.div
      className="bg-primary-dark fixed top-0 left-0 w-full h-full z-40 pb-8 pt-24"
      initial="hidden"
      animate={isOpen ? "visible" : "hidden"}
      variants={menuVariant}
    >
      <div className="container py-8 h-full overflow-y-scroll   text-center ">
        {menu.items &&
          menu.items.map(item => {
            return (
              <motion.div variants={itemsVariants} className="py-4">
                {!item.subnav && <Link to={item.link}>{item.label}</Link>}
                {item.subnav && (
                  <MobileMenuOverlay
                    trigger={
                      <Link
                        to={item.link}
                        onClick={e => {
                          e.preventDefault();
                        }}
                      >
                        {item.label}
                      </Link>
                    }
                  >
                    {item.subnav.map(item => {
                      return (
                        <div className="py-2">
                          {!item.subnav && (
                            <Link to={item.link}>{item.label}</Link>
                          )}
                          {item.subnav && (
                            <MobileMenuOverlay
                              trigger={
                                <Link
                                  target={item.target}
                                  to={item.link}
                                  onClick={e => {
                                    e.preventDefault();
                                  }}
                                >
                                  {item.label}
                                </Link>
                              }
                            >
                              {item.subnav.map(item => {
                                return (
                                  <div className="py-2">
                                    <Link target={item.target} to={item.link}>
                                      {item.label}
                                    </Link>
                                  </div>
                                );
                              })}
                            </MobileMenuOverlay>
                          )}
                        </div>
                      );
                    })}
                  </MobileMenuOverlay>
                )}
              </motion.div>
            );
          })}{" "}
        <hr className="my-4 opacity-25" />
        <div
          variants={itemsVariants}
          div
          className="flex flex-col space-y-4
        justify-center items-center  "
        >
          {/* <motion.div variants={itemsVariants}>
            <LanguagePicker />
          </motion.div> */}
          <motion.div variants={itemsVariants} className="w-full">
            <UserAuthItems close={close} />
          </motion.div>
        </div>
      </div>
    </motion.div>
  );
}

function MobileMenuOverlay({ trigger, children }) {
  const [isOpen, setIsOpen] = useState(false);
  const close = () => {
    setIsOpen(false);
  };
  return (
    <>
      <div
        onClick={() => {
          setIsOpen(!isOpen);
        }}
      >
        {trigger}
      </div>
      <AnimatePresence>
        {isOpen && (
          <motion.div
            initial={{
              pointerEvents: "none",
              opacity: 0,
              height: 0,
              marginTop: 0,
              transition: { ease: "easeIn" },
            }}
            animate={{
              pointerEvents: "auto",
              opacity: 1,
              height: "auto",
              marginTop: 16,
              transition: { ease: "easeOut" },
            }}
            exit={{
              pointerEvents: "none",
              opacity: 0,
              height: 0,
              marginTop: 0,
              transition: { ease: "easeIn" },
            }}
            className="bg-primary  bg-opacity-75 shadow-glow py-2  overflow-hidden"
          >
            {children}
          </motion.div>
        )}
      </AnimatePresence>
    </>
  );
}

function UserAuthItems({ close }) {
  const [user, setUser] = useState(null);
  const [dropdownIsOpen, setDropdownIsOpen] = useState(false);

  function logout() {
    setUser(null);
  }
  useEffect(() => {
    if (typeof window !== "undefined") {
      function clickOutside(e) {
        if (!e.target.closest(".user-auth-ref") || e.target.closest("a")) {
          setDropdownIsOpen(false);
        }
      }
      document.addEventListener("click", clickOutside);
      return () => {
        document.removeEventListener("click", clickOutside);
      };
    }
  }, []);
  useEffect(() => {
    if (window !== undefined) {
      if (window.localStorage.getItem("auth")) {
        setUser({
          name: window.localStorage.getItem("userId"),
        });
      }
    }
  }, [typeof window !== "undefined" && window.localStorage.getItem("userId")]);

  return (
    <>
      {!user && (
        <>
          {" "}
          {/* 
          <Link
            to="/marketplace/login"
            className="tablet:inline-flex tablet:mb-4 text-sm inline-flex items-center space-x-2"
            // onClick={login}
          >
            <IoIosLogIn />
            <span> Sign In</span>
          </Link>
          <br className="lg:hidden" />
          <Link
            to="/marketplace/signup"
            className="tablet:inline-flex  text-sm inline-flex items-center space-x-2"
          >
            <FaUserAlt className="w-3 h-3" />
            <span>Create Epik Account</span>
          </Link>
          */}
        </>
      )}
      {user && (
        <div className="relative user-auth-ref tablet:w-full text-sm">
          <div
            className="inline-flex items-center   font-semibold cursor-pointer"
            onClick={() => {
              setDropdownIsOpen(!dropdownIsOpen);
            }}
          >
            <FaUserAlt className="mr-2" />
            {/* Hello, {user.name} */}
            <BsCaretDownFill className="ml-4" />
          </div>
          <div className="lg:absolute right-0 tablet:w-full  min-w-full overflow-hidden">
            <Collapse isOpen={dropdownIsOpen}>
              <ul className="bg-dark tablet:bg-primary  shadow-glow lg:shadow-none bg-opacity-75 divide-white lg:divide-y divide-opacity-25 px-3 tablet:mt-6 tablet:text-center">
                {" "}
                {/* <li>
                  <Link
                    className="py-3 inline-flex items-center lg:opacity-50 hover:opacity-100"
                    to="/marketplace/favourites"
                    onClick={close}
                  >
                    {" "}
                    <FaHeart className="inline-block mr-2" />
                    Favorites
                  </Link>
                </li> */}
                <li>
                  <Link
                    className="py-3 inline-flex items-center lg:opacity-50 hover:opacity-100"
                    to="/marketplace/inventory"
                    onClick={close}
                  >
                    <FaGem className="inline-block mr-2" /> Items
                  </Link>
                </li>
                {/* <li>
                  <a
                    className="py-3 inline-flex items-center lg:opacity-50 hover:opacity-100"
                    href="#"
                    onClick={close}
                  >
                    <FaGlasses className="inline-block mr-2" /> Whatchlist
                  </a>
                </li>  */}
                <li>
                  <Link
                    className="py-3 inline-flex items-center lg:opacity-50 hover:opacity-100"
                    to="/marketplace/profile"
                    onClick={close}
                  >
                    <FaUserAlt className="inline-block mr-2" /> Profile
                  </Link>
                </li>
                <li>
                  <Link
                    className="py-3 inline-flex items-center lg:opacity-50 hover:opacity-100"
                    to="/marketplace/logout"
                    onClick={close}
                    onClick={logout}
                  >
                    <IoIosLogIn className="inline-block mr-2" /> Logout
                  </Link>
                </li>
              </ul>
            </Collapse>
          </div>
        </div>
      )}
    </>
  );
}
